import React from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';

//Custom components
import OficinaCardInfo from 'components/reservaciones/OficinaCardInfo';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  disponible: {
    cursor: 'pointer',
    borderRightColor: 'green',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },

  noDisponible: {
    cursor: 'not-allowed',
    borderRightColor: 'red',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },
  horaDisponible: {
    fontWeight: 'bold',
  },
  horaNoDisponible: {
    textDecoration: 'line-through',
  },
}));


const OficinaCard = ({ oficinas, dia, selectedHorario, fecha, ocupadosHorarios, onClick, ...props }) => {
  const classes = useStyles();

  const { data = [] } = useSelector(state => state.data_oficinas);
  const todas_oficinas = data.map(d => ({ id: d.id, direccion: d.direccion, nombre: d.nombre, serviciosAsignados: d.serviciosAsignados }));

  const fechaSeleccionadaCalendario = moment(fecha).format('YYYY-MM-DD');

  const cliente = useSelector(state => state.data_clientes.data);
  // console.log({ cliente })

  //Ordenar oficinas por hora de inicio
  oficinas = oficinas.sort((a, b) => (a.h_hora_inicio > b.h_hora_inicio) ? 1 : ((b.h_hora_inicio > a.h_hora_inicio) ? -1 : 0));

  //Pone en ocupado los horarios que han sido reservados
  for (let i = 0; i < oficinas.length; i++) {
    let j = 0;

    const limiteEspaciosDisponibles = (oficinas[i].h_espacios_disponibles) ?? 1;
    let espaciosDisponibles = limiteEspaciosDisponibles;

    // console.log('=======', oficinas[i])
    while (j < ocupadosHorarios.length && !oficinas[i].esReserva) {


      const fechaReserva = moment(ocupadosHorarios[j].inicio).format('YYYY-MM-DD');
      if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva)) {
        const horaReserva = `${moment(ocupadosHorarios[j].inicio).format('HH:mm')}-${moment(ocupadosHorarios[j].fin).format('HH:mm')}`;
        const horaOficina = `${oficinas[i].h_hora_inicio}-${oficinas[i].h_hora_fin}`;

        if (limiteEspaciosDisponibles === 1 && horaOficina === horaReserva) {
          oficinas[i].estado = true;
          oficinas[i].esReserva = true;
        } else if (limiteEspaciosDisponibles > 1 && horaOficina === horaReserva) {
          if (ocupadosHorarios[j].cliente_id === cliente.id) {
            oficinas[i].estado = true;
            oficinas[i].esReserva = true;
            // console.log('cliente con un horariro')
          } else
            espaciosDisponibles--;
        }
      }
      j = j + 1;
    }

    // console.log({ espaciosDisponibles });
    if (limiteEspaciosDisponibles > 1 && espaciosDisponibles === 0) {
      oficinas[i].estado = true;
      oficinas[i].esReserva = true;
    }
  }

  //Pone en ocupado los horarios que ya son anteriores
  for (let i = 0; i < oficinas.length; i++) {
    const nowDiaHora = moment(new Date()).format('YYYY-MM-DD HH:mm')
    const auxConcat = moment(fechaSeleccionadaCalendario + ' ' + oficinas[i].h_hora_inicio)
    const fechaSeleccionadaCalendarioHoras = moment(auxConcat).format('YYYY-MM-DD HH:mm')
    if (moment(fechaSeleccionadaCalendarioHoras).isBefore(nowDiaHora)) {
      oficinas[i].estado = true;
    } else {
      if (oficinas[i].estado === true && !oficinas[i].esReserva) {
        oficinas[i].estado = false;
      }
    }
  }

  const handleCardClick = valor => {
    if (!valor.estado) {
      onClick({
        fecha: fecha,
        hora: `${valor.h_hora_inicio}-${valor.h_hora_fin}`,
        id_oficina: valor.h_oficina_id,
        fechaSeleccionada: fechaSeleccionadaCalendario,
        referencia_oficina: valor.o_direccion,
        servicio_id: valor.servicio_id,
        servicio_nombre: valor.servicio_nombre,
      });
    }
  };

  const setOficinaSelected = valor => {
    onClick(valor);
  };

  return (
    <div>
      <Box borderBottom={1}>
        <Typography display="inline" variant="subtitle1">
          {dia}
        </Typography>
      </Box>
      <GridContainer>
        {oficinas.length > 0 ? (
          todas_oficinas.map((element) => {
            return (
              <OficinaCardInfo key={`${element.id}`} oficina={element} oficinasHorarios={oficinas} classes={classes} selectedHorario={selectedHorario} fechaSeleccionadaCalendario={fechaSeleccionadaCalendario} handleCardClick={handleCardClick} setOficinaSelected={setOficinaSelected} />
            );
          })
        ) : (
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene horarios guardados!
          </Typography>
        )}
      </GridContainer>
    </div>
  );
};

export default OficinaCard;
