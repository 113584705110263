import React, { useState } from 'react';

import { Box, Typography, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import moment from 'moment';


const OficinaCardInfo = ({ oficina, oficinasHorarios, classes, selectedHorario, fechaSeleccionadaCalendario, handleCardClick, setOficinaSelected }) => {
  const serviciosAsignados = oficina.serviciosAsignados;

  let initNombreServicio = 'Consulta';
  if (selectedHorario.id_oficina === oficina.id && selectedHorario.servicio_id) {
    initNombreServicio = selectedHorario.servicio_nombre;
  }
  const [servicioSelected, setServicioSelected] = useState((selectedHorario.id_oficina === oficina.id && selectedHorario.servicio_id) ? selectedHorario.servicio_id : '0');
  const [servicioNombreSelected, setServicioNombreSelected] = useState(initNombreServicio);


  const seleccionado = {
    border: '2px green solid',
    boxShadow: '0 10px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se alce la card y de el efecto de seleccionada
  };

  const noSeleccionado = {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se baje la card y de el efecto de seleccionada
  };

  const onSelectChange = (e) => {
    const newValue = e.target.value;
    const servicioFind = serviciosAsignados.filter(servicio => servicio.id === newValue);
    setServicioSelected(newValue)
    if (newValue === '0') {
      setServicioNombreSelected('Consulta')
      setOficinaSelected({ ...selectedHorario, servicio_id: '0', servicio_nombre: 'Consulta' })
      return;
    }
    if (servicioFind && servicioFind.length > 0) {
      setServicioNombreSelected(servicioFind[0].nombre)
      setOficinaSelected({ ...selectedHorario, servicio_id: newValue, servicio_nombre: servicioFind[0].nombre })
    }
  }

  return (
    <div key={`${oficina.id}`} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ margin: '30px 50px 0px 50px' }}>
        <Typography display="inline" variant="body2">
          <span style={{ fontWeight: 'bold' }}>{oficina.nombre}</span>
        </Typography>
      </Box>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {oficinasHorarios
          .filter(unaOficina => unaOficina.o_id === oficina.id)
          .map((oficinaHorario, index) => {
            const fechaSeleccionada = moment(selectedHorario.fecha).format('YYYY-MM-DD');
            const horaOficina = `${oficinaHorario.h_hora_inicio}-${oficinaHorario.h_hora_fin}`;
            const horaSeleccionada = `${selectedHorario.hora}`;
            // console.log(oficinaHorario)
            return (
              <GridItem sm={2} xs key={index}>
                <Card
                  style={
                    fechaSeleccionada === fechaSeleccionadaCalendario &&
                      horaOficina === horaSeleccionada
                      ? seleccionado
                      : noSeleccionado
                  }
                  key={`${oficinaHorario.h_id}-${index}`}
                  className={oficinaHorario.estado ? classes.noDisponible : classes.disponible}
                  onClick={() => handleCardClick({ ...oficinaHorario, servicio_id: servicioSelected, servicio_nombre: servicioNombreSelected })}
                >
                  <div
                    style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
                    className={classes.stats}
                  >
                    <span
                      style={{ padding: '10px 0' }}
                      className={oficinaHorario.estado ? classes.horaNoDisponible : classes.horaDisponible}
                    >{`${oficinaHorario.h_hora_inicio}/${oficinaHorario.h_hora_fin}`}</span>
                  </div>
                </Card>
              </GridItem>
            );
          })
        }
      </div>
      <div style={{ display: 'flex', padding: '10px', paddingLeft: '20px' }} variant="body2">
        <GridItem sm={6} xs={10}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id={`select-servicio-label_${oficina.id}`}>Servicio seleccionado</InputLabel>
            <Select
              // id={"select-servicio"}
              labelId={`select-servicio-label_${oficina.id}`}
              value={servicioSelected}
              onChange={onSelectChange}
              disabled={selectedHorario.id_oficina !== oficina.id}
            >
              <MenuItem value={'0'}>Consulta</MenuItem>
              {
                serviciosAsignados.map(servicio => (
                  <MenuItem key={servicio.id} value={servicio.id} selected={selectedHorario.servicio_id === servicio.id}>{servicio.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </GridItem>
      </div>
    </div>
  )
}

export default OficinaCardInfo;