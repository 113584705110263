import React from 'react';
// import { Box, Typography, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import { makeStyles } from '@material-ui/core/styles';

//Custom components
import OficinaCard from './oficina-card.component';
import { useSelector } from 'react-redux';

import moment from 'moment';
// import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// moment.locale('es');

// const useStyles = makeStyles(theme => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//     },
//   },
// }));


const OficinaReserva = ({ 
  handleClickCard, 
  selectedHorario, 
  error, 
  selectedDate,
  setSelectedDate,
  selectedDay,
  setSelectedDay
}) => {
  // const classes = useStyles();
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDay, setSelectedDay] = useState(moment(new Date()).format('dddd'));
  const todosHorarios = useSelector(state => state.data_horarios_reservas.data.horarios_oficina);
  const ocupadosHorarios = useSelector(state => state.data_horarios_reservas.data.ocupados);

  const handleDateChange = date => {
    setSelectedDate(date);
    setSelectedDay(moment(date).format('dddd'));
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="YYYY-MM-DD"
            margin="normal"
            id="fecha_reserva"
            label="Elija la fecha"
            minDate={new Date()}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div> 

      <OficinaCard
        fecha={selectedDate}
        dia={selectedDay}
        ocupadosHorarios={ocupadosHorarios}
        selectedHorario={selectedHorario}
        onClick={handleClickCard}
        oficinas={todosHorarios.filter(
          horario_dia => horario_dia.h_dia_semana.toUpperCase() === selectedDay.toUpperCase()
        )}
      />
      {
        (error) &&
        <Alert severity="error">Debe seleccionar un horario para continuar</Alert>

      }
    </div>
  );
};

export default OficinaReserva;
