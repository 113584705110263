import React from 'react';

import { Box, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';


//Icons

//Custom components
import OficinaCardInfo from 'components/reservaciones/OficinaCardInfo';
//Styles
// import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import { makeStyles } from '@material-ui/core/styles';

//Tiempo
import moment from 'moment';
import 'moment/locale/es';
// moment.locale('es');

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  disponible: {
    cursor: 'pointer',
    borderRightColor: 'green',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },

  noDisponible: {
    cursor: 'not-allowed',
    borderRightColor: 'red',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },
  horaDisponible: {
    fontWeight: 'bold',
  },
  horaNoDisponible: {
    textDecoration: 'line-through',
  },
}));

// const useStyles = makeStyles(styles);

const OficinaCard = ({ oficinas,oficinasH, dia, selectedHorario, fecha, ocupadosHorarios, onClick, ...props }) => {
  const classes = useStyles();
  const todas_oficinas = oficinasH.map(d => ({ id: d.id, direccion: d.direccion, nombre: d.nombre, serviciosAsignados: d.serviciosAsignados }));

  const fechaSeleccionadaCalendario = moment(fecha).format('YYYY-MM-DD');
  oficinas = oficinas.sort((a,b) => (a.h_hora_inicio > b.h_hora_inicio) ? 1 : ((b.h_hora_inicio > a.h_hora_inicio) ? -1 : 0));


    //Pone en ocupado los horarios que han sido reservados
  // for (let i = 0; i < oficinas.length; i++) {
  //   for (let j = 0; j < ocupadosHorarios.length; j++) {
  //     const fechaReserva = moment(ocupadosHorarios[j].inicio).format('YYYY-MM-DD');
  //     const horaReserva = `${moment(ocupadosHorarios[j].inicio).format('HH:mm')}-${moment(
  //       ocupadosHorarios[j].fin
  //       ).format('HH:mm')}`;
  //       const horaOficina = `${oficinas[i].h_hora_inicio}-${oficinas[i].h_hora_fin}`;
  //       if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva) && horaOficina === horaReserva) {
  //       oficinas[i].estado = true;
  //       oficinas[i].esReserva = true;
  //      }else{
  //       oficinas[i].esReserva = false;
  //      }
  //   }
  // }
    for (let i = 0; i < oficinas.length; i++) {
      let j = 0;  
      
      while (j<ocupadosHorarios.length && !oficinas[i].esReserva ) {
        
        const fechaReserva = moment(ocupadosHorarios[j].inicio).format('YYYY-MM-DD');
        if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva)) {
          const horaReserva = `${moment(ocupadosHorarios[j].inicio).format('HH:mm')}-${moment( ocupadosHorarios[j].fin ).format('HH:mm')}`;
          const horaOficina = `${oficinas[i].h_hora_inicio}-${oficinas[i].h_hora_fin}`;

          
          if (horaOficina === horaReserva) {
          oficinas[i].estado = true;
          oficinas[i].esReserva = true;
        }
        }
        j=j+1;
      }
    } 


   //Pone en ocupado los horarios que ya son anteriores
   for (let i = 0; i < oficinas.length; i++) {
      const nowDiaHora = moment(new Date()).format('YYYY-MM-DD HH:mm')
      const auxConcat = moment(fechaSeleccionadaCalendario + ' ' +oficinas[i].h_hora_inicio)
      const fechaSeleccionadaCalendarioHoras =  moment(auxConcat).format('YYYY-MM-DD HH:mm')
      if(moment(fechaSeleccionadaCalendarioHoras).isBefore(nowDiaHora) ){
        oficinas[i].estado = true;
      } else {
        if(oficinas[i].estado === true && !oficinas[i].esReserva){
            oficinas[i].estado = false;
        }
    }
  }


  const handleCardClick = valor => {
    if (!valor.estado) {
      onClick({
        fecha: fecha,
        hora: `${valor.h_hora_inicio}-${valor.h_hora_fin}`,
        id_oficina: valor.h_oficina_id,
        fechaSeleccionada: fechaSeleccionadaCalendario,
        referencia_oficina: valor.o_direccion,
        servicio_id: valor.servicio_id,
        servicio_nombre: valor.servicio_nombre,
      });
    }
  };

  const setOficinaSelected = valor => {
    onClick(valor);
  };

  return (
    <div>
      <Box borderBottom={1}>
        <Typography display="inline" variant="subtitle1">
          {dia}
        </Typography>
      </Box>
      <GridContainer>
        {oficinas.length > 0 ? (
          todas_oficinas.map(element => {

            return (
              <OficinaCardInfo key={`${element.id}`}  oficina={element} oficinasHorarios={oficinas} classes={classes} selectedHorario={selectedHorario} fechaSeleccionadaCalendario={fechaSeleccionadaCalendario} handleCardClick={handleCardClick} setOficinaSelected={setOficinaSelected}/>  
            );
          })
        ) : (
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene horarios guardados!
          </Typography>
        )}
      </GridContainer>
    </div>
  );
};

export default OficinaCard;
